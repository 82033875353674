export default function getConfig() {
    return {
     
        ELDER_INFURA_ENDPOINT: process.env.REACT_APP_ELDER_INFURA_ENDPOINT,
        ELDER_CONTRACT_ADDRESS: process.env.REACT_APP_ELDER_CONTRACT_ADDRESS,
        ELDER_ABI: process.env.REACT_APP_ELDER_ABI,
        WIZBUDS_INFURA_ENDPOINT: process.env.REACT_APP_WIZBUDS_INFURA_ENDPOINT,
        WIZBUDS_CONTRACT_ADDRESS: process.env.REACT_APP_WIZBUDS_CONTRACT_ADDRESS,
        WIZBUDS_ABI: process.env.REACT_APP_WIZBUDS_ABI,
        NETWORK_VERSION: process.env.REACT_APP_NETWORK_VERSION,
        NETWORK: process.env.REACT_APP_NETWORK,
        EXPLORER_URL: process.env.REACT_APP_EXPLORER_URL,
        API: process.env.REACT_APP_API,
        INFURA_ENDPOINT: process.env.REACT_APP_INFURA_ENDPOINT
               
    }
}