import React from 'react';
import MainStudios from './mainStudios.jsx';

const Index = () => {

	return(

		<MainStudios />

	)

}

export default Index;