import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import styles from './menu.module.scss';
import logo from '../../assets/logo.png';
import discord from '../../assets/discord.png';
import twitter from '../../assets/twitter.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getMenu } from '../../utils/getMenu';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { FaBars } from 'react-icons/fa';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';




const Menu = () => {

	const mobile = useMediaQuery('(max-width:768px)');
	const options = getMenu();

	

	return( 
		
		<div>
						
				<Grid container spacing={2} className={styles.container}>
			        <Grid item xs={5}>	        	
			        	<Link to="/"><img src={logo} className={styles.logo} /></Link>
			        </Grid>
			        <Grid item xs={7}>
				        {
								mobile ?
										
									<div className={styles.menu}>
										<ul>
											<li key="1"><Link to="/">Info</Link></li>
											<li key="2"><Link to="/mint">Mint</Link></li>
										</ul>
									</div>
								:
									<div className={styles.menu}>
										<ul>
											{options.map((option, index) => (													
												<li key={index}><Link to={option.to}>{option.name}</Link></li>
											))}
											<li><a href="https://discord.com/invite/wizbudsnft" target="_blank"><img src={discord} className={styles.social}/></a></li>
											<li><a href="https://twitter.com/WizBudsNFT" target="_blank"><img src={twitter} className={styles.social}/></a></li>
										</ul>
									</div>
								}
			        </Grid>       
			    </Grid>			
		</div>
	)

}

export default Menu;