import React, { useState  } from 'react';
import styles from './auction.module.scss';
import Grid from '@mui/material/Grid';
import stoneChair from '../../../../assets/stoneChair.mp4';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { getAuctions } from './auction.js';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Auction = () => {

	AOS.init();

	const [ value, setValue ] = useState();

	const items = getAuctions();
 
	return(

		<div className={styles.container} data-aos="fade-up">
			
			<h1 className={styles.title}>elder auction</h1>
			<p className={styles.subtitle}><span className={styles.purple}>10 Elder WizBuds</span> will be auctioned after the free mint</p>

			<br/>
			<br/>

			<Grid container spacing={2} data-aos="fade-up">

				{

					items.map((item, index) => (
						<Grid item sm={3} xs={12} key={index} className={`${index <= 2 ? styles.borderRight : ""}`}>
			        		<img src={item.image} data-aos="fade-up" alt="" className={styles.box}  />			        		
			        		<br/>
			        		<Grid container spacing={2} data-aos="fade-up" className={styles.action}>
				        		<Grid item sm={5} xs={6}>
				        			<div className={styles.frameBid}>
					        			<p className={styles.bid}>Starting Bid</p>
					        			<p className={styles.total}>0.5 ETH</p>
					        		</div>
				        		</Grid>
				        		<Grid item sm={7} xs={6} data-aos="fade-up">
				        			<div className={styles.frameEnd}>
				        				<p className={styles.end}>Auction end in</p>
				        				<p className={styles.clock}>00:00:00:00</p>		        				
				        			</div>
				        		</Grid>		        		
				        	</Grid>

				        	<div className={styles.frameValueExternal} data-aos="fade-up">

					        	<div className={styles.frameValue}>

						        	<div className={styles.entry}>		        					
										<Grid container>
											<Grid item xs={6} className={styles.field}>Balance</Grid>
											<Grid item xs={6} className={styles.value2}>0 ETH</Grid>
										</Grid>			        				
									</div>
									<br/>
									<div className={styles.entry}>		        					
										<Grid container>
											<Grid item xs={6} className={styles.field}>Minimun Bid</Grid>
											<Grid item xs={6} className={styles.value2}>0 ETH</Grid>
										</Grid>			        				
									</div>  

									<div className={styles.inputValue}>								
										<input type="text" className={styles.input} /> ETH
									</div>
									{/*
									<div className={styles.btnBid}>
										<Button variant="contained">BID</Button>
									</div>
									*/}

									<p className={styles.info}>Make sure your metamask wallet is connected</p>

								</div>
								
							</div>

							<div className={styles.fooText} data-aos="fade-up">
								<p className={styles.fooTitle}>Bid History</p>
								<p className={styles.fooSubtitle}>No bid was made. Be the first</p>
							</div>

			        	</Grid>
					))
				}				
			</Grid>

			<div className={styles.section} data-aos="fade-up">
			    <Grid container spacing={2}>			        
			        <Grid item sm={6} xs={12}>		           	
			           	<h1 className={styles.titleSection}>Why Being an Elder?</h1>
			        	<div className={styles.text}>
				        	<p>This table will host 105 1/1 wizards, those who are alphas in the space, those who firmly believe in our project, want to be part of it and push WizBuds as high as possible with their guidance, advice and opinion.</p>
				        	<p>They will enjoy the 1/1 Wizbuds, <span className={styles.green}>more information</span> about what we are building, a <span className={styles.purple}>free mint</span> of the generative collection and <span className={styles.green}>extra benefits</span> in future drops.</p>
				        	<p>Also WizBuds will give them <span className={styles.purple}>two stone seats per elder</span>. They will be able to sell those seats if they want. The owners of the seats will enjoy access to the Stone table group, access to a WL for the generative collection.</p>
						</div>	
			        </Grid>       
			        <Grid item sm={6} xs={12}>	        	
			        	<video className={styles.video} loop autoplay="" muted >
						  <source src={stoneChair} type="video/mp4" />					  
						  Your browser does not support the video tag.
						</video>
			        </Grid>
			    </Grid>
			</div>	

		</div>

	)

}

export default Auction;