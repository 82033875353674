import React from 'react';
import Auction from './auction.jsx';

const Index = () => {

	return(

		<Auction />

	)

}

export default Index;