import React from 'react';
import styles from './contentFuture.module.scss';
import Grid from '@mui/material/Grid';
import base_web_05 from '../../../../assets/base_web_05.webp';
import base_web_12 from '../../../../assets/base_web_12.webp';
import pergamino from '../../../../assets/pergamino.webp';
import base_web_11b from '../../../../assets/base_web_11b.webp';
import base_web_03 from '../../../../assets/base_web_03.webp';
import base_web_06 from '../../../../assets/base_web_06.webp';
import base_web_04 from '../../../../assets/base_web_04.webp';
import base_web_07 from '../../../../assets/base_web_07.webp';
import base_web_04b from '../../../../assets/base_web_04b.webp';
import mago_maquina from '../../../../assets/mago_maquina.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContentFuture = () => {
 
	AOS.init();

	return(
 
		<div className={styles.container}>

			<div className={styles.section} data-aos="fade-up">
				<Grid container spacing={2}>
			        <Grid item sm={6} xs={12}>	        	
			        	<h1 className={styles.title}>creating an <br />ecosystem</h1>
			        	<div className={styles.text}>
				        	<p>In The Studios we want to create an ecosystem of stories, characters and meaning around WizBuds. We have already planned different projects for the future and some of them are already being explored and developed within The Studios. We will be removing the blanket of the map as we announce those projects.</p>
				        	<p>Our goals are pushing WizBuds IP by generating partnerships, merch, developing WizBuds lore in social media, etc. and developing a solid project with as much utility as possible. For this we need to be in the loop: Plan, develop, create, repeat.  Luckily we are in that loop and we won't give up until wizbuds is known everywhere.</p>
				        	<p>We offer hard work, original ideas and magic. We also need your patience and time to work carefully and deliver nothing but true value.</p>
						</div>			        
			        </Grid>
			        <Grid item sm={6} xs={12}>
			           	<img src={base_web_03} alt="" className={styles.image} />
			        </Grid>       
			    </Grid>
			</div>

			<div className={styles.section} data-aos="fade-up">
				<Grid container spacing={2}>
			        <Grid item sm={6} xs={12}>
			           	<img src={base_web_05} alt="" className={styles.image} />
			        </Grid>  
			        <Grid item sm={6} xs={12}>	        	
			        	<h1 className={styles.title}>Goal Focused <br/> Community</h1>
			        	<div className={styles.text}>
							<p>Wizbuds have a clear goal: Be a collectibles brand and build a web 3 space to create, build and grow together, 
								what’s called “the studios”.</p>

							<p>Every wizbud will be the pillar of the ecosystem and the reason why wizbuds moves forward. 
							For that reason, the wizbuds will be the rarest collectible of our universe and will enjoy all 
							the features and rewards coming from the studios.</p>
				        </div>			        
			        </Grid>			            
			    </Grid>
			</div>

			<div className={styles.section} data-aos="fade-up">
			    <Grid container spacing={2}>			       
			        <Grid item sm={6} xs={12}>		           	
			           	<h1 className={styles.title}>the stone table <br />of wizbuds</h1>
			        	<div className={styles.text}>
				        	<p>We strongly believe that in a brand strictly built by the community the development team can’t take a wrong path that could end up hurting the community. Due to this, besides of being always super open to suggestions and opinions from out community, we'll create <span className={styles.purple}>"The stone table of WizBuds"</span></p>
				        	<p>This table will host <span className={styles.green}>105 1/1 WizBuds</span>, those who are alphas in the space, those who firmly believe in our project, want to be part of it and push WizBuds as high as possible with their guidance, advice and opinion.</p>
				        	<p>This alpha group will not only enjoy more perks and benefits, they'll get privileged information about every process that's being built by the team.</p>
						</div>	
			        </Grid>  
			        <Grid item sm={6} xs={12}>	        	
			        	<img src={base_web_12} alt="" className={styles.image} />
			        </Grid>     
			    </Grid>
			</div>

			<div className={styles.section} data-aos="fade-up">
			    <Grid container spacing={2}>
			        <Grid item sm={6} xs={12}>	        	
			        	<img src={base_web_06} alt="" className={styles.image} />
			        </Grid>
			        <Grid item sm={6} xs={12}>		           	
			           	<h1 className={styles.title}>creating magic <br />together</h1>
			        	<div className={styles.text}>
							<p>We want to encourage our wizbuds to build and create magic. That is why every wizbuds will be able to build their own collection of Pixel Wizbuds.</p>
							<p>Holders will be able to design their own flag to represent their collection, set any price they want and offer any utility. </p>
							<p>The best part about this is that every subcollection will be like a double utility NFT as it will obtain utilities from their creator, but also from the Wizbuds Team.</p>
						</div>	
			        </Grid>       
			    </Grid>
			</div>

			<div className={styles.section} data-aos="fade-up">
				<Grid container spacing={2}>
			        <Grid item sm={6} xs={12}>	        	
			        	<h1 className={styles.title}>the crystal <br/> stone of creation</h1>
			        	<div className={styles.text}>
				        	<p>The legend says that a mysterious wizard, the first one who returned to civilization after thousands of years, was amazed by modern entertainment. Without doubt and having his vision of the studios in mind he used his magic powers to enchant a particular Stone. By the time he finished casting the spell, the stone was shiny, and a lot of crystals popped out of it. He called it «The Crystal Stone of Creation». Each wizard has its own Stone stored In a secret location now, which allows our magic friends to receive NFTs Creations effortlessly.</p>
				        	<p>Nevertheless they have to go on a mission to find his Stone.</p>
						</div>			        
			        </Grid>
			        <Grid item sm={6} xs={12}>
			           	<img src={pergamino} alt="" className={styles.image} />
			        </Grid>       
			    </Grid>
			</div>

			<div className={styles.section} data-aos="fade-up">
				<Grid container spacing={2}>
			        <Grid item sm={6} xs={12}>
			           	<img src={base_web_04} alt="" className={styles.image} />
			        </Grid>  
			        <Grid item sm={6} xs={12}>	        	
			        	<div className={styles.titleMagic}>
				        	<img src={base_web_07} className={styles.coin} />
				        	<h1 className={styles.title}> magic token</h1>
				        </div>
			        	<div className={styles.text}>
				        	<p>We found different utilities for this token that can solve many issues. We are still exploring and working on details but we won't launch a token until we found many utilities for it and WizBuds ecosystem benefit from it instead of being hurt by it.</p>
				        	<p>We will announce further details, dates and everything related to it.</p>
						</div>			        
			        </Grid>		
						           
			    </Grid>
			</div>							
		</div>

	)

}

export default ContentFuture;