import React from 'react';
import styles from './bannerMagic.module.scss';
import twitter_b from '../../assets/twitter_b.png';
import discord_b from '../../assets/discord_b.png';
import Button from '@mui/material/Button';

const BannerMagic = () => {

	return( 
		
		<div className={styles.bannerMagic}>

			<p className={styles.title}><span>create magic</span><sup>TM</sup></p>
			
			<div className={styles.btnDiscord}>
				<a href="https://discord.com/invite/wizbudsnft" target="_blank">
					<Button>
						<img src={discord_b} className={styles.discord}/>
						join discord
					</Button>
				</a>
			</div>
  
			<div className={styles.btnTwitter}>
				<a href="https://twitter.com/WizBudsNFT" target="_blank">
					<Button>
						<img src={twitter_b} className={styles.twitter}/>						
					</Button>
				</a>
			</div>
		
			
		</div>
		
	)

}

export default BannerMagic;