import Web3 from 'web3';
import getConfig  from '../config/config';
import { callGet } from './call';

const config = getConfig()

export async function safeMint(value, quantity) {

	if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
 
		let res;

		// 1. Create Transaction Parameters

		res = await _createTransactionParameters(value, quantity);
		if(res['type'] == 'error'){
			return res;
		}

		const transactionParameters = res['transactionParameters'];

		
		// 2. Simulate Transaction

		res = await _simulateTransaction(transactionParameters);
		if(res['type'] == 'error'){
			return res;
		}


		// 3. Send Transaction

		res = await _sendTransaction(transactionParameters);
		if(res['type'] == 'error'){
			return res;
		}
		

		// 4. Successful message

		return res;


	}

  	return null;

}

async function _createTransactionParameters(value, quantity){

	const web3 = new Web3(config.INFURA_ENDPOINT);
	const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	const account = accounts[0];
	const networkVersion = await window.ethereum.request({ method: 'net_version' })

	if(networkVersion == config.NETWORK_VERSION){

		const abi = JSON.parse(config.WIZBUDS_ABI);
		const contract_address = config.WIZBUDS_CONTRACT_ADDRESS;
		
		const contract = new web3.eth.Contract(abi, contract_address);	
		const gasLimit = await web3.utils.toHex( 400000 );
		
		let type = 1; // merkle root 
		if(!value){
			type = 2; // free merkle root
		}	

		let endpoint, res;
		let leaf, proof, data;

		if(type == 2){
			endpoint = `getMerkleProof/${account}/${type}`;
			res = await callGet(endpoint);	

			if(res['type'] == "success"){				
				leaf = res['leaf'];
				proof = res['proof'];
			}else{
				return { type: "error", message: res['message'] };
			}
		}else{
			if(type == 1){				
				leaf = "0x5931b4ed56ace4c46b68524cb5bcbf4195f1bbaacbe5228fbd090546c88dd229";
				proof = [ "0x44a8eeff8b2e26e1a27bdcfbfd6915b035db0d14d74305dedb56d173c30d6088", "0x5e889256cc890cb706274d7138e786f934befdeab86110429a4402659ee3a1da", "0xd1ca8207a09d7e7db30b153d1ec9e713eee61814a20475f4118b2bd24ab1f724" ];
			}
		}
		
		if(value > 0){
			data = await contract.methods.mint(leaf, proof, quantity).encodeABI();
		}else{
			data = await contract.methods.freeMint(leaf, proof, quantity).encodeABI();
		}

		value = await web3.utils.toHex( await web3.utils.toWei(value.toString(),'ether') );

		const chainId = await web3.utils.toHex(networkVersion);

		const transactionParameters = {
		  nonce: '0x00', // ignored by MetaMask			  
		  gas: gasLimit, // gasLimit customizable by user during MetaMask confirmation.
		  to: contract_address, // Required except during contract publications.
		  from: account, // must match user's active address.
		  value: value, //
		  data: data, // Optional, but used for defining smart contract creation and interaction.
		  chainId: chainId
		};

		return {type: "success", "transactionParameters": transactionParameters}
	
	}

	return { type: "error", "message": "invalid network version" }

}

async function _simulateTransaction(transactionParameters){

	try{
		const web3 = new Web3(window.ethereum);
		const res = await web3.eth.call(transactionParameters);
		return { type: "success" }
	}catch(e){				

		const startResult = e.message.search('{')
		const endResult = e.message.lastIndexOf('}')+1;
		
	    if (startResult >= 0) {
	       let blockResult = e.message.substring(startResult, endResult);	       
	       blockResult = JSON.parse(blockResult);
	       let message;
	       if(blockResult.originalError){
	       	  message = blockResult.originalError.message;
	       }else{
	       	 message = blockResult.message
	       }	       
	       return { type: "error", message: message  }
	    }

	    return { type: "error", message: "unknown error or insufficient funds" }

	}

}

async function _sendTransaction(transactionParameters){

	let txHash, receipt, error;

	try {

		const web3 = new Web3(window.ethereum);

		await new Promise((resolve, reject) => {

			web3.eth.sendTransaction(transactionParameters)
			.once('transactionHash', function(_txHash){
				txHash = _txHash;
			})
			.once('receipt', function(_receipt){
			    receipt = _receipt;
			})
			.on('confirmation', function(confirmationNumber, receipt){
			  	if (confirmationNumber >= 3) {
			        resolve()
			    }
			})
			.on('error', function(_error){
				error = _error;
				reject(error)
			})

		})

		if(error){
			return { type: "error", message: error }
		}		

		return {
			type: "success",
			txHash: txHash,
			from: receipt.from,
			to: receipt.to,
			explorer: `${config.EXPLORER_URL}/tx/${txHash}`
		}

	}catch(e){
		return { type: "error", message: e }
	}

}