const formatDecimals = async (num, decimals) => {
			
	num = parseFloat(num).toFixed(decimals+1);
    num = num.substring(0,num.length-1);           

    return num;

};

export { formatDecimals };


	