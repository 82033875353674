import React, { useState, useEffect } from 'react';
import styles from './mint.module.scss';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import videoBud from '../../../../assets/videos/elders.mp4';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { connectWallet } from '../../../../utils/connectWallet';
import { safeMint } from '../../../../utils/safeMint';
import { formatDecimals } from '../../../../utils/formatDecimals';
import { getSupply } from '../../../../utils/getSupply';
import getConfig  from '../../../../config/config';
import Web3 from 'web3';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const Mint = () => {

	const config = getConfig()

	AOS.init();
   
	const [ connectedWallet, setConnectedWallet ] = useState(false);	
	const [ balance, setBalance ] = useState(0);
	const [ account, setAccount ] = useState(0);
	const [ totalSupply, setTotalSupply ] = useState(0);
	const [ error, setError ] = useState("");
	const [ explorer, setExplorer ] = useState("");
	const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);
	

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	};

	window.ethereum?.on('accountsChanged', async function(){
		showBalance();
	});

	useEffect(() => {
	  (async () => {
	    try {
	      const res = await getSupply();
	      if(res['type'] == "success"){
	      	setTotalSupply(res['totalSupply'])
	      }	      
	    } catch (err) {
	      console.error(err);
	    }
	  })();
	}, [])

	 
	
	const onConnectWallet = async () => {
	    
		const res = await connectWallet();
		
		if(res['type'] == 'success'){
			setError("");
			setConnectedWallet(true);
			setAccount(res['account']);			
			const decimals = 8;
			setBalance( await formatDecimals(res['balance'],decimals) );					
		}else{
			if(res['type'] == "error"){				
				setError(res['message']);				
			}
		}

	};

	const onMint = async () => {		

		let res;

		setError("Minting...");

		res = await safeMint();
		if(res['type'] == "success"){
			setError("");
			res = await getSupply();
		    if(res['type'] == "success"){
		      	setTotalSupply(res['totalSupply'])
		    }
		    showBalance();
		    setExplorer(res['explorer']);
		    setOpen(true);
		}else{			
			setError(res['message'])
		}

	}

	async function showBalance(){

		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const account = accounts[0];

		const web3 = new Web3(config.INFURA_ENDPOINT);
		const balance = await web3.utils.fromWei(await window.web3.eth.getBalance(account),'ether')

		const decimals = 8;
		setBalance( await formatDecimals(balance,decimals) )

	}

	return(
    
		<div className={styles.content} data-aos="fade-up">
 			 
		    <Modal
		        open={open}
		        onClose={handleClose}		        
		        aria-labelledby="modal-modal-title"
		        aria-describedby="modal-modal-description"		        
		      >
		        <Box sx={style}>
		        	<div className={styles.modalText}>
		          		<p className={styles.titleOver}>Congratulation!</p>
		          		<p className={styles.comment}>Has a new NFT</p>
		          		<p className={styles.explorer}>View on explorer <br/> <a href={explorer} target="_blank">Etherscan</a></p>
		        	</div>
		        </Box>
		    </Modal>


			<Grid container spacing={2}>
		        <Grid item sm={5} xs={12} data-aos="fade-up">	        	
		        	<h2 className={styles.subtitle}>Members of ths Stone Table</h2>	
		        	<h1 className={styles.title}>elder wizards</h1>										        	
					<div className={styles.paragraph}>
						<p>The first leaders of the WizBuds were called <span className={styles.purple}>"The Elders"</span>. 105 of them used to discuss every important decision on "The sacred stone table", which led them to rule the village and the community, leading it to endless times of prosperity. Each one of these elders have some unique and specific quality that made them worthy of a spot in the stone table.</p>
						<p>These 105 members were worshiped as gods, but that was centuries ago, and the spots remained empty for a long time, until now.</p>
						<p>The wizards are looking for the second generation of Elders, a group of members extremely passionate about nft's that can contribute enormously to the project with their advice.</p>
						<p>Do you have what’s necessary to earn a spot on the table?</p>
					</div>
		        </Grid>
		        <Grid item sm={2} xs={12}></Grid>
		        <Grid item sm={5} xs={12} data-aos="fade-up">
		          	
		        	<video className={styles.video}  loop autoplay="" muted >
					  <source src={videoBud} type="video/mp4" />					  
					  Your browser does not support the video tag.
					</video> 

		          	<div className={styles.mint}>
		          		<Grid container spacing={2}>
		        			
		        			<Grid item xs={12} className={styles.der}>
		        				<p className={styles.title}>mint nft</p>
		        				<p className={styles.number}>{totalSupply}/90</p>
		        				<div className={styles.entry}>
		        					<Grid container>
		        						<Grid item xs={6} className={styles.field}>Balance</Grid>
		        						<Grid item xs={6} className={styles.value}>{balance} ETH</Grid>
		        					</Grid>
		        				</div>
		        				<br />
		        				
		        				<p className={styles.error}>{error}</p>
		        				<div className={styles.btn}>
		        					{
		        						!connectedWallet ? 
		        						<Button onClick={onConnectWallet}>CONNECT WALLET</Button>
		        						:
		        						<Button onClick={() => onMint(account)}>MINT</Button>
		        					}

		        						
		        				</div>
		        				<p className={styles.info}>Make sure your Metamask wallet is connected</p>
		        			</Grid>
		        		</Grid>
		          	</div>
		        </Grid>       
		    </Grid>

					
		</div>

	)

}

export default Mint;