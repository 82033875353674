import Web3 from 'web3';
import getConfig  from '../config/config';


// Metamask Connection

const connectWallet = async () => {
	
	const config = getConfig()	
	 
	if (typeof window.ethereum !== 'undefined') {

		console.log('MetaMask is installed!');
		window.web3 = new Web3(window.ethereum);		
							
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const account = accounts[0];			
		
		const networkVersion = await window.ethereum.request({ method: 'net_version' })

		if(networkVersion == config.NETWORK_VERSION){
			
			const web3 = new Web3(config.INFURA_ENDPOINT);
			const balance = await web3.utils.fromWei(await window.web3.eth.getBalance(account),'ether')
					
		}
		
		return { 'type': 'success', networkVersion: networkVersion };		

	}	

	return {'type': 'error', message: "MetaMask is not installed" };

};

export { connectWallet };


	