import getConfig  from '../config/config';

const config = getConfig()

const callGet = async (endpoint) => {
	
	const res = await fetch(`${config.API}/${endpoint}`,{
		method: 'GET',
		headers: new Headers({
			'Content-type': 'application/json',
			Accept: 'application/json',
		})
	});
		
	return await res.json();		
	
}

const callPost = async (endpoint, data) => {

	const res = await fetch(endpoint,{
		method: 'POST',	
		body: JSON.stringify(data),	
		headers: new Headers({
			'Content-type': 'application/json',
			Accept: 'application/json',
		}),
	});
		
	return await res.json();		
	
}



export { callGet, callPost };

