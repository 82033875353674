import photo from '../../../../assets/photo.png';
import msalcedo from '../../../../assets/team/msalcedo.jpg';
import mcorrea from '../../../../assets/team/mcorrea.jpeg';
import mmarino from '../../../../assets/team/mmarino.jpeg';
import jmartiarena from '../../../../assets/team/jmartiarena.jpeg';
import jgiordano from '../../../../assets/team/jgiordano.jpeg';
import jcass from '../../../../assets/team/jcass.jpeg';
import jmarino from '../../../../assets/team/jmarino.jpeg';
import jsilva from '../../../../assets/team/jsilva.jpeg';
import jmoreno from '../../../../assets/team/jmoreno.jpeg';


const getTeam = () => {

	const team = [
		// panel
		{ photo: jgiordano, name: "Juan G", cargo: "Founder - Project Manager", linkedin: "https://www.linkedin.com/in/juan-giordano-0a0173187" },
		{ photo: mcorrea, name: "Maximiliano Correa", cargo: "Backend and Blockchain developer", linkedin: "https://www.linkedin.com/in/maximiliano-correa-a650a956" },
		{ photo: jcass, name: "Jacob Cass", cargo: "Brand designer", linkedin: "https://www.linkedin.com/in/justcreativedesign" },
		{ photo: jmoreno, name: "Jose Maria Moreno", cargo: "Community Manager", linkedin: "https://www.linkedin.com/in/jose-maria-moreno-sanchez-2366b0220" },
		{ photo: msalcedo, name: "Maria Salcedo", cargo: "2D Artist", linkedin: "https://www.linkedin.com/in/maria-g-salcedo" },
		{ photo: jsilva, name: "Joaco Silva", cargo: "2D Artist", linkedin: "https://www.linkedin.com/in/joaquin-silva88" },
		{ photo: mmarino, name: "Maximiliano Marino", cargo: "Community Engagement Manager", linkedin: "https://www.linkedin.com/in/maximiliano-marino-09571b212" },
		{ photo: jmarino, name: "Juan Marino", cargo: "3D Artist", linkedin: "https://www.linkedin.com/in/juan-cruz-marino" },
		{ photo: jmartiarena, name: "Juan Martiarena", cargo: "Recruiter.  Management.", linkedin: "https://www.linkedin.com/in/juanmartiarena" }
		
	];

	return team;

};

export { getTeam };