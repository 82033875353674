import React from 'react';
import box from '../../../../assets/wizard_box.png';

const getAuctions = () => {

	const items = [
		{ image: box, startBid: 0.14, auctionEnd: "00:00:00:00"},
		{ image: box, startBid: 0.14, auctionEnd: "00:00:00:00"},
		{ image: box, startBid: 0.14, auctionEnd: "00:00:00:00"},
		{ image: box, startBid: 0.14, auctionEnd: "00:00:00:00"}
	];

	return items;

};

export { getAuctions };