import React from 'react';
import ContentFuture from './contentFuture.jsx';
import AudioButton from '../../../../components/audioButton/audioButton.jsx';

const Index = () => {

	return(
		<>
			<ContentFuture />
			<AudioButton />
		</>
	)

}

export default Index;