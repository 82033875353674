import React, { useState, useRef, useEffect } from 'react';
import styles from './slider.module.scss';
import Grid from '@mui/material/Grid';
import pintor from '../../../../assets/pintor.webp';
import linkedin from '../../../../assets/linkedin.png';
import { getTeam } from './slider.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
  
const Slider = () => {


	AOS.init();

	const s1 = useRef(null);
	const s2 = useRef(null);
	const s3 = useRef(null);
	const s4 = useRef(null);
	const s5 = useRef(null);
	const s6 = useRef(null);

	const [ classNameS1, setClassNameS1 ] = useState(styles.active);	
	const [ classNameS2, setClassNameS2 ] = useState(styles.inactive);
	const [ classNameS3, setClassNameS3 ] = useState(styles.inactive);
	const [ classNameS4, setClassNameS4 ] = useState(styles.inactive);
	const [ classNameS5, setClassNameS5 ] = useState(styles.inactive);
	const [ classNameS6, setClassNameS6 ] = useState(styles.inactive);

	const [ enableScroll, setEnableScroll ] = useState(true);
		
	const team = getTeam();


	function removeStyle(){

		setClassNameS1(styles.inactive);
		setClassNameS2(styles.inactive);
		setClassNameS3(styles.inactive);
		setClassNameS4(styles.inactive);
		setClassNameS5(styles.inactive);
		setClassNameS6(styles.inactive);

	}

	const onSelector = (selector) =>{
					
		removeStyle();

		switch(selector){
			case 's1':
				setClassNameS1(styles.active);
				s1.current.scrollIntoView();
			break;
			case 's2':
				setClassNameS2(styles.active);
				s2.current.scrollIntoView();
			break;
			case 's3':
				setClassNameS3(styles.active);
				s3.current.scrollIntoView();
			break;
			case 's4':
				setClassNameS4(styles.active);
				s4.current.scrollIntoView();
			break;
			case 's5':
				setClassNameS5(styles.active);
				s5.current.scrollIntoView();
			break;
			case 's6':
				setClassNameS6(styles.active);
				s6.current.scrollIntoView();
			break;
		}	

	}


	/* Detecta scroll */
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);

  const onScroll = (e) => {
  	
	    const currentScrollY = e.target.scrollTop;
	    if (prevScrollY.current < currentScrollY && goingUp) {
	      setGoingUp(false);
	    }
	    if (prevScrollY.current > currentScrollY && !goingUp) {
	      setGoingUp(true);
	    }
	    prevScrollY.current = currentScrollY;
	    	    
	    console.log(currentScrollY);

	    if(currentScrollY <= 60){
	    	removeStyle();
	    	setClassNameS1(styles.active);
	    }

	    if(currentScrollY >= 365 && currentScrollY <= 467){
	    	removeStyle();
	    	setClassNameS2(styles.active);
	    }

	    if(currentScrollY >= 671 && currentScrollY <= 773){
	    	removeStyle();
	    	setClassNameS3(styles.active);
	    }

	    if(currentScrollY >= 1079 && currentScrollY <= 1181){
	    	removeStyle();
	    	setClassNameS4(styles.active);
	    }

	    if(currentScrollY >= 1691 && currentScrollY <= 1793){
	    	removeStyle();
	    	setClassNameS5(styles.active);
	    }

	    if(currentScrollY >= 2405 && currentScrollY <= 2507){
	    	removeStyle();
	    	setClassNameS6(styles.active);
	    }   

	

  }

	

	return(

		<div className={styles.container}>
			<div className={styles.backImage}>

				<Grid container spacing={2} data-aos="fade-up">				
	        <Grid item xs={1}>  
	        	<div className={styles.selector}>		        		
	        		<div className={`${styles.option} ${classNameS1}`} onClick={() => onSelector('s1')}></div>
	        		<div className={`${styles.option} ${classNameS2}`} onClick={() => onSelector('s2')}></div>
	        		<div className={`${styles.option} ${classNameS3}`} onClick={() => onSelector('s3')}></div>
	        		<div className={`${styles.option} ${classNameS4}`} onClick={() => onSelector('s4')}></div>
	        		<div className={`${styles.option} ${classNameS5}`} onClick={() => onSelector('s5')}></div>
	        		<div className={`${styles.option} ${classNameS6}`} onClick={() => onSelector('s6')}></div>
	        	</div>
	        </Grid>
	        <Grid item sm={11} xs={11}>		
	        	<div className={styles.hideScroll}>				        
			      	<div className={styles.sector} onScroll={onScroll}>
         
			      		<Grid container spacing={2}>
				      		<Grid item sm={6} xs={11}>	

				    			<h1 className={styles.title} ref={s1}>welcome to <br/> the studios</h1>		        			
				    			<div className={styles.text}>
				    				<p>WizBuds Studios, a place to create, earn and build into the web3.</p>
				    			    
				    			    <p>Formed by Wizbuds that give you access to 
				    			    The Studios. Where you will receive rewards from every
				    			    project  built or powered inside of it.</p>
				    			    
				    			    <p>Inside our Studios, we will work to expand our image, 
									create excellent tools for our wizbuds, and much more.</p>
				    			</div>
						    				        				        		      		
				    			<h1 className={styles.title} ref={s2}>A friendly and magical Place</h1>		        			
				    			<div className={styles.text}>
				    				<p>We are ready to grow by helping others to grow as well. By creating and building our community will evolve. This is a place where imagination prevails, a place where the most creative projects and stories will be created.</p>
				    				<p> As a WizBud we'll use our magic to create amazing things. Are you ready to create magic together?</p>
				    			</div>
				    	
				    			<h1 className={styles.title} ref={s3}>What is a wizbud</h1>		        			
				    			<div className={styles.text}>
				    				<p>A WizBud is your magical partner. With him you will live multiple adventures, create magic and also, He might let you enter the studios. There we'll create and develop different projects and initiatives, not only to improve the IP of WizBuds™, but also to reward our Holders.</p>
				    			</div>
				    	
				    			<h1 className={styles.title} ref={s4}>LORE</h1>		        			
				    			<div className={styles.text}>
					      			<p>A long time ago, a small village inhabited by WizBuds lived in complete harmony. Once it was discovered by humans, they wanted to steal their powers to seek selfish goals. These WizBuds, knowing what could happen, decided to erase all proof of their existence and hide from civilization, knowing that this would avoid a worldwide conflict.</p>
					      			<p>Days passed, years passed, even decades passed. After all this time, a brave WizBud decided to give humanity a second chance.</p>
					      			<p>A modern society was appearing in front of their eyes where everyone lived in peace. Even better, humans provided these wizards a huge improvement in their lives: entertainment.</p>
					      			<p>Hiding all those years from the civilizations meant that WizBuds were living a boring and monotonous life, but all this changed when humans showed them cinema, videogames and the art world.</p>
					      			<p>Amazed by this huge discovery, wizards used their ancient powers to create "Wizbuds Studios", a place to develop their own projects. </p>
					      		</div>
						    	
				    			<h1 className={styles.title} ref={s5}>values</h1>
				    			<div className={styles.text}>
					      			<p><span className={styles.purple}>Creativity: </span>As wizards they were born with an inner skill that granted them the ability to build anything. However, in that time they were guided to perform the same tasks as the rest and stick with the established. Those brave enough to escape the monotony and create The Studios had a clear goal: Build and create their own projects, following their instinct and paving the way.</p>		        			
					      			<p><span className={styles.green}>Kindness: </span>The Studios were built as a team, and the support between every single Wizbud was essential to achieve it.  All of the WizBuds provided any needed help without expecting anything in return. Every single one of them are convinced that this type of kindness is 100% necessary to flourish.</p>		        			
					      			<p><span className={styles.purple}>Joy: </span>Everything's better with fun. The WizBuds faced many tough moments during their journey, but happiness and laughter is something that they never lacked off. They wait for you to join The Studios with open arms, where you'll smile and enjoy your stay.</p>		        			
					      			<p><span className={styles.green}>Bravery: </span>Going against the crowd and following your own path is not an easy task, bravery and courage is needed. Wizbuds maintained those skills even in the darkest of times. Nowadays they're thankful for not giving up and encourage their members to keep going no matter what.</p>
				    			</div>

				    			<h1 className={styles.title} ref={s6}>team</h1>
				    			<div className={ ` ${styles.text} ${styles.team}` }>
					      			<Grid container spacing={2}>
					      				{
									        team.map((eq, index) => {
									        	return(
											       <Grid key={index} item sm={3} xs={6} className={styles.teamContent}>
					        							<div >
					        								<img src={eq.photo} className={styles.photo} />
					        								<p className={styles.name}>{eq.name}</p>
					        								<p className={styles.cargo}>{eq.cargo}</p>
					        								<a href={eq.linkedin} target="_blank"><img src={linkedin} className={styles.linkedin} /></a>
					        							</div>
					        						</Grid>
											      )
										      })
										    }
					        		</Grid>
				        		</div>

					        	<h1 className={styles.title}>T</h1>

						      </Grid>

				        </Grid>

			        </div>
			      </div>        					        		       
	        </Grid>          
			  </Grid>
			</div>
		</div>
	)

}
 
export default Slider;