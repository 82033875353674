import React from 'react';

import styles from './header.module.scss';


const Header = () => {

	return(

		<div className={styles.container}>
			<h1 className={styles.title}>vision</h1>
			<p className={styles.text}>
				Imagine a magic place deep into the web3. A place where <br/>
				a couple of wizards use their powers to build and create. <br />
				A place that is full of joy, good vibes and creativity.
			</p>
		</div>
  
	)

}
 
export default Header;