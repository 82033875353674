import React from 'react';
import Footer from './footer.jsx';

const Index = () => {

	return(

		<Footer />

	)

}

export default Index;