import React from 'react';
import MainFuture from './mainFuture.jsx';

const Index = () => {

	return(

		<MainFuture />

	)

}

export default Index;