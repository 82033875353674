import React from 'react';

import Menu from '../../../../components/menu';
import styles from './mainElder.module.scss';
import Mint from '../mint';
import Auction from '../auction';
import BannerMagic from '../../../../components/bannerMagic';
import Footer from '../../../../components/footer';
import { animateScroll as scroll } from 'react-scroll';
import AudioButton from '../../../../components/audioButton/audioButton';

const MainElder = () => {

	scroll.scrollTo(0); // Arriba a 0px

	return(

		<div className={styles.container}>
			<div className={styles.contentElder}>
				<Menu />
				<Mint />
				<Auction /> 
			</div>
			<BannerMagic />
			<Footer />
			<AudioButton />
		</div>

	)

}

export default MainElder;