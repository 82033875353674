import React from 'react';

import Menu from '../../../../components/menu';
import styles from './mainStudios.module.scss';
import BannerMagic from '../../../../components/bannerMagic';
import Footer from '../../../../components/footer';
import Header from '../header';
import Slider from '../slider';
import { animateScroll as scroll } from 'react-scroll';


const MainStudios = () => {

	scroll.scrollTo(0); // Arriba a 0px
 
	return(

		<div className={styles.container}>
			<div className={styles.contentStudios}>				
				<Menu />
				<Header />
			</div>
			<Slider />
			<BannerMagic />
			<Footer />
		</div>

	)

}

export default MainStudios;