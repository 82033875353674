import React from 'react';
import Menu from './menu.jsx';

const Index = () => {

	return(

		<Menu />

	)

}

export default Index;