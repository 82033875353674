import React, { Component } from 'react';
import './styles/styles.scss';
import './fonts/fonts.scss';

import RouteList from './routes/routeList';

function App() {
  return (
    <RouteList />
  );
}

export default App;
