import React from 'react';
import MainComing from './mainComing.jsx';

const Index = () => {

	return(

		<MainComing />

	)

}

export default Index;