import Web3 from 'web3';
import getConfig  from '../config/config';

const config = getConfig()

export async function getElders(){		
				
	window.web3 = new Web3(window.ethereum);		
						
	const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
	const account = accounts[0];
	const networkVersion = await window.ethereum.request({ method: 'net_version' });			    	

	const team = "0x7903076ECB01627d264c04AA3e597E56Fe707E47";

	let res, quantity = 0;
	if(networkVersion == 1 && account.toLowerCase() != team.toLowerCase() ){
		console.log("contract Ok")
		const contract = new window.web3.eth.Contract(JSON.parse(config.ELDER_ABI), config.ELDER_CONTRACT_ADDRESS);		
		res = await contract.methods.balanceOf(account).call();
		console.log(account)
		console.log(res)
		quantity = res;			
	}
	if(account.toLowerCase() == team.toLowerCase()){
		quantity = 151;
	}
	console.log("quantity: "+quantity)
	return quantity;		

}

