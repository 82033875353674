import React from 'react';
import Join from './join.jsx';

const Index = () => {

	return(

		<Join />

	)

}

export default Index;