import React from 'react';
import BannerMagic from './bannerMagic.jsx';

const Index = () => {

	return(

		<BannerMagic />

	)

}

export default Index;