import React from 'react';
import styles from './mainComing.module.scss';
import Menu from '../../../../components/menu';
import Join from '../join';
import AudioButton from '../../../../components/audioButton/audioButton';


const MainComing = () => {

	return(

		<div className={styles.container}>			
			<Menu />
			<Join />
			<AudioButton />
		</div>

	)

}

export default MainComing;