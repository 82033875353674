import React from 'react'
import Grid from '@mui/material/Grid';
import styles from './section.module.scss'
import wizardImg from '../../../../assets/icons/Wizard.png'
import lightImg from '../../../../assets/icons/luz-del-cielo.png'
import VipImg from '../../../../assets/icons/VIP.png'

const SectionWeb3 = (props) => {
	return(
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={0}
			className={styles.sectionWeb}
		>
			<Grid item xs={12} align="center">
				<img src={wizardImg} alt="Wizard" className={styles.placeholderCircleBig} />
			</Grid>
			<Grid container 
				justifyContent="center"
				sx={{ marginTop: '48px'}}>
				<Grid item xs={12} sm={5} md={3} lg={2} align="center">
					<div className={styles.level} />
					<div className={styles.subtitle}>Level UP</div>
				</Grid>
				<Grid item xs={12} sm={5} md={3} lg={2} align="center">
					<div className={styles.star} />
					<div className={styles.subtitle}>Gain Ranks</div>
				</Grid>
				<Grid item xs={12} sm={5} md={3} lg={2} align="center">
					<div className={styles.medal} />
					<div className={styles.subtitle}>Earn Achivements</div>
				</Grid>
				<Grid item xs={12} sm={5} md={3} lg={2} align="center">
					<div className={styles.share} />
					<div className={styles.subtitle}>Connect with community</div>
				</Grid>
			</Grid>
			<Grid item xs={12} align="center" >
				<div className={styles.textLong}>
					Wizbud is a collectible, a pfp and also the key access to the studios.
					Where you will be able to enjoy an interactive experience by staking your wizbuds.
				</div>
			</Grid>
			<Grid container 
				justifyContent="center"
				>
				<Grid item xs={12} md={5} align="center">
					<img src={lightImg} alt="Light from the sky" className={styles.placeholderSquare} />
					<div className={styles.text}>
						A feature to connect, evolve and reward the most loyal holders.
					</div>
				</Grid>
				<Grid item xs={12} md={5} align="center">
					<img src={VipImg} alt="VIP" className={styles.placeholderSquare} />
					<div className={styles.text}>
						Gain new levels and ranks to unlock better rewards.
					</div>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SectionWeb3