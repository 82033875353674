import React from 'react';
import styles from './header.module.scss';
import mapa from '../../../../assets/mapa.test.final-WEB.webp';

const Header = () => {

	return(

		<div className={styles.container}>
			<div className={styles.title}>a look to the future...</div>
			<img src={mapa} className={styles.mapa}/>
		</div>

	)
  
}

export default Header;