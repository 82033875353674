import React from 'react';
import Slider from './slider.jsx';
import AudioButton from '../../../../components/audioButton/audioButton.jsx';


const Index = () => {

	return(
		<>
			<Slider />
			<AudioButton />
		</>
	)

}

export default Index;