import React from 'react'
import Grid from '@mui/material/Grid';
import styles from './section.module.scss'
import wizbudImg from '../../../../assets/icons/Maguito-Solo.png'
import daVinciImg from '../../../../assets/icons/Wizard-da-Vinci.png'
import closedBookImg from '../../../../assets/icons/libro-cerrado.png'
import openBookImg from '../../../../assets/icons/Libro-abierto.png'
import folderImg from '../../../../assets/icons/Carpeta.png'

const SectionArt = (props) => {
	return(
		<div className={styles.sectionContainer}>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0}
				className={styles.sectionGrid}
			>
				<Grid item xs={12} align="center">
					<div className={styles.lineDot}></div>
					<div className={styles.lineDivider}></div>
					<div className={styles.lineCircle}></div>
				</Grid>
				<Grid item xs={12} sm={3} lg={3} align="center" sx={{my: '24px'}}>
					<img src={ wizbudImg } alt="Wizbud" className={styles.artIcon} />
					<div className={styles.subtitle}>Wizbuds</div>
				</Grid>
				<Grid item xs={12} sm={3} lg={5} align="center" className={styles.text} sx={{py: '48px'}}>
					A wizbud will be the main character of wizbuds universe.
				</Grid>
				<Grid item xs={12} sm={3} lg={3} align="center" sx={{my: '24px'}}>
				<img src={ closedBookImg } alt="Closed book" className={styles.artIcon} />
					<div className={styles.subtitle}>Story</div>
				</Grid>
			</Grid>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0}
				className={styles.sectionGrid}
			>
				<Grid item xs={12} align="center">
					<div className={styles.lineDot}></div>
					<div className={styles.lineDivider}></div>
					<div className={styles.lineCircle}></div>
				</Grid>
				<Grid item  xs={12} sm={3} lg={3} align="center" sx={{my: '24px'}}>
					<img src={ daVinciImg } alt="Da Vinci Wizbud" className={styles.artIcon} />
				</Grid>
				<Grid item  xs={12} sm={3} lg={5} align="center" className={styles.text} sx={{py: '48px'}}>
					Universe expansion and new characters.
				</Grid>
				<Grid item  xs={12} sm={3} lg={3} align="center" sx={{my: '24px'}}>
					<img src={ openBookImg } alt="Open book" className={styles.artIcon} />
				</Grid>
			</Grid>

			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={0}
				className={styles.sectionGrid}
			>
				<Grid item xs={12} align="center">
					<div className={styles.lineDot}></div>
					<div className={styles.lineDivider}></div>
					<div className={styles.lineCircle}></div>
				</Grid>
				<Grid item xs={12} align="center" sx={{ marginTop: '24px'}}>
					<img src={ folderImg } alt="Folder" className={styles.artIcon} />
				</Grid>
				<Grid item xs={12} align="center" sx={{ marginTop: '24px', marginBottom: '48px'}}>
					<div className={styles.text}>
						Storytelling across web 2 and web 3. Physical collectibles.
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default SectionArt