import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import logo from '../../../../assets/logo800.png';
import styles from './join.module.scss';
import discord_b from '../../../../assets/discord_b.png';
  
  
const Join = () => {

	return(

		<Grid container spacing={2} className={styles.container}>
			<Grid item xs={12}>
				<div className={styles.content}>
					<img src={logo} alt="" className={styles.logo} />
					<p className={styles.title}><span>create magic</span><sup>TM</sup></p>					
					<div className={styles.btnJoin}>
						<a href="https://discord.com/invite/wizbudsnft" target="_blank">
							<Button>
								<img src={discord_b} className={styles.discord}/>
								join discord
							</Button>
						</a>
					</div>
				</div>
			</Grid>
		</Grid>

	)

}

export default Join;