import Web3 from 'web3';
import getConfig  from '../config/config';

const config = getConfig()

// Metamask Connection

const getSupply = async () => {
	
	const web3 = new Web3(config.INFURA_ENDPOINT);
	const contract = new web3.eth.Contract(JSON.parse(config.ABI), config.CONTRACT_ADDRESS);
	const totalSupply = await contract.methods.totalSupply().call();			

	return { 'type': 'success', totalSupply: totalSupply };


};

export { getSupply };


	