import React from 'react';

const getMenu = () => {

	const options = [
		{ to: "/info", name: "info" },
		{ to: "/mint", name: "mint" },
		{ to: "/studios", name: "the studios"},
		{ to: "/future", name: "future" },
		{ to: "/elder", name: "elder wizbuds" }
	];

	return options;

};

export { getMenu };