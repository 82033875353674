import React from 'react';

import Menu from '../../../../components/menu';
import styles from './mainFuture.module.scss';
import Header from '../header';
import ContentFuture from '../contentFuture';
import BannerMagic from '../../../../components/bannerMagic';
import Footer from '../../../../components/footer';
import { animateScroll as scroll } from 'react-scroll';


const MainFuture = () => {

	scroll.scrollTo(0); // Arriba a 0px

	return(

		<div className={styles.container}>
			<Menu />			
			<Header />		
			<ContentFuture />
			<BannerMagic />
			<Footer />
		</div>

	)

}

export default MainFuture;