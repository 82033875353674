import React from 'react'
import { useCountdown } from '../../../../hooks/useCountdown';
import styles from "./countdown.module.scss";
const { DateTime } = require("luxon");

const Countdown = () => {
	const targetDate = DateTime.utc(2022, 11, 18, 16, 0, 0 );

  const [countDown, days, hours, minutes, seconds] = useCountdown(targetDate);

	if ( countDown > 0 ) {
		return(
			<div className={styles.container}>
				<div className={styles.timer}>
					{days.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:
					{hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:
					{minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:
					{seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}
					<div className={styles.legend}>
						<div>Days</div>
						<div>Hs</div>
						<div>Min</div>
						<div>Sec</div>
					</div>
				</div>
			</div>
		)
	} else {
		return ( <></> )
	}
}

export default Countdown