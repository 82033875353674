import React from 'react';
import Grid from '@mui/material/Grid'
import audio from '../../assets/music/wizbuds-music.mpeg'
import styles from './audioButton.module.scss';


const AudioButton = () => {
  return (
    <Grid item xs={12} className={styles.audio}>
        <audio controls loop autoplay="" >
            <source src={audio} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    </Grid>
  )
};

export default AudioButton;