import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// pages
import MainComing from '../pages/public/coming/mainComing';
import Studios from '../pages/public/studios/mainStudios';
import Future from '../pages/public/future/mainFuture';
import Elder from '../pages/public/elder/mainElder';
import MainInfo from '../pages/public/info/mainInfo/mainInfo';
import MainMint from '../pages/public/mint/mainMint';

const RoutesList = () => {

	return( 
		<Router>
		    <Routes>
                <Route exact path="/" element={<MainComing/>}/>
                <Route exact path="/studios" element={<Studios/>}/>
                <Route exact path="/future" element={<Future/>}/>
                <Route exact path="/elder" element={<Elder/>}/>
                <Route exact path="/info" element={<MainInfo/>}/>
                <Route exact path="/mint" element={<MainMint/>}/>
            </Routes>
		</Router>
		
	)

}

export default RoutesList;
