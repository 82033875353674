import React from 'react'
import Grid from '@mui/material/Grid';
import styles from './section.module.scss'

const SectionTrade = (props) => {
	return(
		<Grid
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
			spacing={3}
			className={styles.sectionWeb}
		>
			<Grid item xs={12} align="center">
				<div className={styles.placeholderCircleBig} />
			</Grid>
			<Grid item xs={12} align="center">
				<div className={styles.textLong}>
					Wizbuds will focus on ip development, Creating new characters, worlds, stories, and even physical collectibles.
					But while the universe of wizbuds grows our holders will be able to monetize their NFTS.
					How?
				</div>
			</Grid>
			<Grid item xs={10} lg={8} xl={6} align="center">
				<video className={styles.video} controls>
					<source src="https://drive.google.com/uc?export=download&id=1LWaPKGR4XuFsstF8iSDPmLv2drZyZKL7" type="video/mp4" />					  
					Your browser does not support the video tag.
				</video>
			</Grid>
		</Grid>
	)
}

export default SectionTrade