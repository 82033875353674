import { React, useEffect, useState } from "react";
import Menu from "../../../components/menu";
import styles from "./mainMint.module.scss";
import Grid from "@mui/material/Grid";
import AudioButton from "../../../components/audioButton/audioButton";
import centralImage from "../../../assets/testgif.gif";
import vipImage from "../../../assets/VIP.png";
import bannerImage from "../../../assets/mint-banner.png";
import coverImage from "../../../assets/mint-intro.png";
import Button from "@mui/material/Button";
import Countdown from "./components/countdown";
import { connectWallet } from "../../../utils/connectWallet";
import { getElders } from "../../../utils/getElders";
import { safeMint } from "../../../utils/safeMint";
import { useCountdown } from "../../../hooks/useCountdown";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import getConfig  from '../../../config/config';

const { DateTime } = require("luxon");
const config = getConfig()

const MainMint = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [value, setValue] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [cover, setCover] = useState(true);
  const [stage, setStage] = useState(0);
  const [modalErrorText, setModalErrorText] = useState("");
  const [quantityElders, setQuantityElders] = useState(0);

  const targetDate = DateTime.utc(2022, 11, 18, 16, 0, 0);
  const targetDate2 = DateTime.utc(2022, 11, 18, 19, 0, 0);
  const targetDate3 = DateTime.utc(2022, 11, 18, 22, 0, 0);

  const [countDown] = useCountdown(targetDate);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState("loading");

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);


  const setStageColor = () => {
    let date = DateTime.utc();

    if ( !cover ) {
      if (date > targetDate3) {
        setStage(3);
        document.getElementById("stage1").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage2").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage3").style.filter = "brightness(1) grayscale(0)"
      } else if (date > targetDate2) {
        setStage(2);
        document.getElementById("stage1").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage2").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage3").style.filter = "brightness(1) grayscale(0)"
      } else if (date > targetDate) {
        setStage(1);
        document.getElementById("stage1").style.filter = "brightness(1) grayscale(0)"
        document.getElementById("stage2").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage3").style.filter = "brightness(0.7) grayscale(1)"
      } else {
        setStage(0);
        document.getElementById("stage1").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage2").style.filter = "brightness(0.7) grayscale(1)"
        document.getElementById("stage3").style.filter = "brightness(0.7) grayscale(1)"
      }
    }    
  };

  useEffect(() => {
    setStageColor()
  }, [countDown]);

  const onSelectMint = async (value, quantity) => {   

    setValue(value)
    setQuantity(quantity)

    document.getElementById("mint1").style.backgroundColor = "transparent"
    document.getElementById("mint2").style.backgroundColor = "transparent"
    document.getElementById("mint3").style.backgroundColor = "transparent"
    document.getElementById("mint4").style.backgroundColor = "transparent"
    document.getElementById("mint5").style.backgroundColor = "transparent"
    document.getElementById("mint"+quantity).style.setProperty(
      "background-color",
      "#06C18D",
      "important"
    );
  };


  if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
    window.ethereum.on('networkChanged', function(networkId){
      if(networkId == config.NETWORK_VERSION){
        setIsConnected(true);
      }else{
        setIsConnected(false);
      }
    });
  }

  const onMintNow = async () => {

    let res;    
    
    if (!isConnected) {
      
      res = await connectWallet();      
     
      if(res['type'] == "error"){
        setLoading("error");
        setModalErrorText(res["message"])
        setOpen(true)
      }

      if(res['type'] == "success"){        
        if(res['networkVersion'] == config.NETWORK_VERSION){
          setIsConnected(true);           
          setQuantityElders(await getElders());         
        }else{
          setLoading("error");
          setModalErrorText(`To be able to mint you must use ${config.NETWORK.charAt(0).toUpperCase()+config.NETWORK.slice(1)}. Change it from Metamask`)
          setOpen(true)
        }
        
      }
      
    }else{

      if(value && quantity){
        setLoading("loading");
        setOpen(true)
        res = await safeMint(value, quantity);          
        if(res["type"] == "success") {       
          setLoading("success");
          setOpen(true)
        }
        if(res["type"] == "error") {        
          setLoading("error");
          setModalErrorText(res['message'])
          setOpen(true)
        }

      }else{
        setLoading("error");
        setModalErrorText("Pick a mint")
        setOpen(true)
      }

    }

  };
  
  const onClaim = async () => {

    // Buscar quantity

    if (!isConnected) {

      setLoading("error");
      setModalErrorText("Connect your wallet to receive the reward")
      setOpen(true)

      return;

    }

    const value = 0;
    const claimQuantity = await getElders();
    setQuantityElders(claimQuantity);    
    if(claimQuantity){
      setLoading("loading");
      setOpen(true)
      console.log("claimQuantity: "+claimQuantity)
      const res = await safeMint(value, claimQuantity);
      if (res["type"] == "error") {
        setLoading("error");
        setModalErrorText(res["message"])
        setOpen(true)
      }
      if (res["type"] == "success") {
        setLoading("vip");    
        setOpen(true)
      }
    }

  };

  const onCoverClick = (event) => {
    event.currentTarget.style.opacity = "0";
    setTimeout(function () {
      setCover(false);
    }, 500);
  };

  if (cover) {
    return (
      <div className={styles.container}>
        <Menu />
        <div
          onClick={onCoverClick}
          className={styles.cover}
          style={{ backgroundImage: `url(${coverImage})`, cursor: "pointer" }}
        />
        <AudioButton />
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Menu />
        <div
          className={styles.banner}
          style={{ backgroundImage: `url(${bannerImage})` }}
        />
        <Grid
          container
          className={styles.mintContainer}
          justifyContent="center"
        >
          <div className={styles.stage} id="stage1">
            <div className={styles.stageTitle}> Stage 1 </div>
            <div className={styles.stageSubtitle}> Whitelist 1 + Elders </div>
            <div className={styles.stageText}>
              Max 3 NFT per wallet (1 of 3)
            </div>
            <p className={styles.hour}>4 pm UTC</p>
          </div>
          <div className={styles.stage} id="stage2">
            <div className={styles.stageTitle}> Stage 2 </div>
            <div className={styles.stageSubtitle}> Wled Phase 2 </div>
            <div className={styles.stageText}>
              Max 3 NFT per wallet (1 of 3)
            </div>            
          </div>
          <div className={styles.stage} id="stage3">
            <div className={styles.stageTitle}> Stage 3 </div>
            <div className={styles.stageSubtitle}> Public </div>                      
          </div>
          <Grid item xs={12} align="center">
            <div
              className={styles.centralImg}
              style={{ backgroundImage: `url(${centralImage})` }}
            />
          </Grid>
          <Grid item sx={{ m: "24px" }}>
            <Grid
              item
              container
              className={styles.mintSection}
              justifyContent="center"
              xs="auto"
            >
              <div className={styles.mint}>
                <Button
                  id="mint1"
                  onClick={() => onSelectMint(0.05, 1)}
                  className={styles.mintButtonSmall}
                >
                  Mint 1
                </Button>
                <Button disabled={true} className={styles.pricing}> 0.05 ETH</Button>                
              </div>
              <div className={styles.mint}>
                <Button
                  id="mint2"
                  onClick={() => onSelectMint(0.095, 2)}
                  className={styles.mintButtonSmall}
                >
                  Mint 2
                </Button>
                <div className={styles.pricing}>0.095 ETH</div>
              </div>
              <div className={styles.mint}>
                <Button
                  id="mint3"
                  onClick={() => onSelectMint(0.14, 3)}
                  className={styles.mintButtonSmall}
                >
                  Mint 3
                </Button>
                <div className={styles.pricing}>0.14 ETH</div>
              </div>
              <div className={styles.mint}>
                <Button
                  id="mint4"
                  onClick={() => onSelectMint(0.185, 4)}
                  className={styles.mintButtonSmall}
                >
                  Mint 4
                </Button>
                <div className={styles.pricing}>0.185 ETH</div>
              </div>
              <div className={styles.mint}>
                <Button
                  id="mint5"
                  onClick={() => onSelectMint(0.23, 5)}
                  className={styles.mintButtonSmall}
                >
                  Mint 5
                </Button>
                <div className={styles.pricing}>0.23 ETH</div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} align="center">            
            <Button onClick={onMintNow} className={styles.mintButton}>
              { isConnected? 'Mint Now' : 'Connect Wallet'}
            </Button>            
          </Grid>
          {quantityElders ?
            <Grid item xs={12} align="center">
              <Button onClick={onClaim} className={styles.claimButton}>
                Claim your reward!
              </Button>
            </Grid>
            :null
          }
        </Grid>
        <Countdown />
        <AudioButton />

        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.modal}>
            {loading==="success" && (
              <>
                <div className={styles.modalTitle}>
                  CONGRATULATIONS!
                </div>
                <div className={styles.modalText}>
                  <span style={{color: '#00FFA1'}}>{quantity} Wizbud</span> has been sent to your wallet!
                </div>
                <Button onClick={closeModal} className={styles.modalButton}>
                  Close
                </Button>
              </>
            )}
            {loading==="loading" && (
              <>
                <div className={styles.modalTitle}>
                  Sending some magic!
                </div>
                <div className={styles.modalText}>
                  Please wait...
                </div>
              </>
            )}
              {loading==="error" && (
              <>
                <div className={styles.modalTitle}>
                  Something went wrong...
                </div>
                <div className={styles.modalText}>
                  {modalErrorText}
                </div>
                <Button onClick={closeModal} className={styles.modalButton}>
                  Close
                </Button>
              </>
            )}
            {loading==="vip" && (
              <>
                <img src={vipImage} className={styles.vipImg} alt="vip" />
                <div className={styles.modalTitle}>
                  CONGRATULATIONS!
                </div>
                <div className={styles.modalText}>
                  We have detected <span style={{color: '#00FFA1'}}>{quantityElders}</span> elder wizbuds on your wallet. You have <span style={{color: '#00FFA1'}}>{quantityElders} Free Wizbud</span> now!
                </div>
                <Button onClick={closeModal} className={styles.modalButton}>
                  Close
                </Button>
              </>
            )}
          </Box>
        </Modal>

      </div>
    );
  }
};

export default MainMint;
