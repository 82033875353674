import React from 'react';
import { Link } from "react-router-dom";
import styles from './footer.module.scss';
import logo from '../../assets/logo800.png';
import magicLabs from '../../assets/magicLabs.png';

const Footer = () => {

	return( 
		 
		<div className={styles.footer}>
			<div className={styles.content}>
			 	<Link to="/"><img src={logo} alt="" className={styles.logo} /></Link>
			 	<p className={styles.title}><span>create magic</span><sup>TM</sup></p>
			 	<div className={styles.menu}>
					<ul>
						<li><Link to="/info">info</Link></li>					    					    
						<li><Link to="/studios">the studios</Link></li>
						<li><Link to="/future">future</Link></li>
						<li><Link to="/elder">elder</Link></li>
					</ul>
				</div>
			</div>

			<br/><br/>						

			<p className={styles.sign}>
				<span className={styles.top}>Created by</span> <br /> 
				<img src={magicLabs} alt="" className={styles.magicLabs} />
			</p>

		</div>
		
	)

}

export default Footer;