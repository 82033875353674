import React from 'react';
import Header from './header.jsx';

const Index = () => {

	return(

		<Header />

	)

}

export default Index;