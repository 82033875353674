import React from 'react'
import Menu from '../../../../components/menu'
import styles from './mainInfo.module.scss'
import SectionHeader from '../components/sectionHeader'
import SectionArt from '../components/sectionArt'
import SectionWeb3 from '../components/sectionWeb3'
import SectionTrade from '../components/sectionTrade'
import Grid from '@mui/material/Grid'
import banner2 from '../../../../assets/banners/banner2.png'
import banner3 from '../../../../assets/banners/banner3.png'
import gifBud from '../../../../assets/videos/sequence.gif'
import videoBud from '../../../../assets/videos/elders.mp4'
import AudioButton from '../../../../components/audioButton/audioButton'

const MainInfo = () => {
	return(
		<Grid
			container
			direction="row"
			className={styles.container}
			spacing={0}
		>
			<Grid item xs={12}>
				<Menu />	
			</Grid>
			<Grid item xs={12} align="center" className={styles.artHeader}>
				<SectionHeader title="More than just art" />
			</Grid>
			<Grid item 
				container 
				xs={12} 
				className={styles.placeholder}
				direction="row"
				justifyContent="center"
			>
				<Grid item xs={8} md={4} lg={3} align="center" sx={{my: '24px'}}>
					<img src={ gifBud } alt="Wizbud gif" className={styles.gifs} />
					<div className={styles.subtitle}>Wizbuds</div>
				</Grid>
				<Grid item xs={0} md={1} lg={2}></Grid>
				<Grid item xs={8} md={4} lg={3} align="center" sx={{my: '24px'}}>
					<video className={styles.video} loop autoplay="" muted >
						<source src={videoBud} type="video/mp4" />					  
						Your browser does not support the video tag.
					</video>
					<div className={styles.subtitle}>Elder Wizbuds</div>
				</Grid>
			</Grid>
			<Grid item xs={12} className={styles.sectionGrid}>
				<SectionArt />
			</Grid>
			<Grid item xs={12} align="center" sx={{paddingLeft: '0px !important'}}>
				<SectionHeader title="More than web 3 identity" />
			</Grid>
			<Grid item xs={12} className={styles.placeholder}>
				<div className={styles.banner} style={{ backgroundImage: `url(${banner2})` }}/>
			</Grid>
			<Grid item xs={12} className={styles.sectionGrid}>
				<SectionWeb3 />
			</Grid>
			<Grid item xs={12} align="center" sx={{paddingLeft: '0px !important'}}>
				<SectionHeader title="More than a trade" />	
			</Grid>
			<Grid item xs={12} className={styles.placeholder}>
				<div className={styles.banner} style={{ backgroundImage: `url(${banner3})` }}/>
			</Grid>
			<Grid item xs={12} className={styles.sectionGrid}>
				<SectionTrade />
			</Grid>
		<AudioButton />	
		</Grid>
	)
}

export default MainInfo;