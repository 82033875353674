import React from 'react';
import Mint from './mint.jsx';

const Index = () => {

	return(

		<Mint />

	)

}

export default Index;